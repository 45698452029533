<div
  id="safari {{ _breakpoint() }}"
  [ngClass]="{
    Large: _breakpoint() === 'Large',
    Desktop: _breakpoint() === 'Desktop',
    iPadPro: _breakpoint() === 'iPadPro',
    iPad: _breakpoint() === 'iPad',
    iPhone: _breakpoint() === 'iPhone',
  }">
  <div class="footer">
    <mat-grid-list
      class="wide-view-1140 footer-toolbar"
      [cols]="gridlist[0].cols"
      [rowHeight]="gridlist[0].rowHeight"
      [gutterSize]="gridlist[0].gutterSize">
      <mat-grid-tile
        class="footer-left-1 flex-align-start"
        [colspan]="tilesleft[0].cols"
        [rowspan]="tilesleft[0].rows">
        <div class="foot-top">
          <div class="foot-header type5-headline-3">Get in touch</div>
          <div class="call call-type ft-phone-no">
            <a href="tel:+14158146676">Call (415) 814-6676 ext 5</a>
          </div>
          <div class="hours type6-caption">
            <div>Mo - Fr • 9am - 4pm</div>
            <div>San Francisco, California</div>
          </div>
          <div class="email nav-type">
            <a routerLink="/inquire">Send an email</a>
          </div>
        </div>
      </mat-grid-tile>

      @if (_breakpoint() !== 'iPhone' && _breakpoint() !== 'iPad') {
        <mat-grid-tile
          class="flex-align-start"
          [colspan]="tilesmiddle[0].cols"
          [rowspan]="tilesmiddle[0].rows"
          ><div class="foot-header type5-headline-3">&nbsp;</div></mat-grid-tile
        >
      }
      @if (_breakpoint() === 'iPad') {
        <mat-grid-tile
          class="flex-align-start"
          [colspan]="tilesleft[0].cols"
          [rowspan]="tilesleft[0].rows"
          ><div class="foot-header type5-headline-3">&nbsp;</div></mat-grid-tile
        >
      }

      <mat-grid-tile
        class="flex-align-start"
        [colspan]="tilesright[0].cols"
        [rowspan]="tilesright[0].rows">
        <div class="foot-top">
          <div class="foot-header type5-headline-3">Explore</div>
          <div class="right-footer-nav nav-type">
            <div><a routerLink="/guided-safaris">About us</a></div>
            <div><a routerLink="/savoir-faire">What’s new?</a></div>
            <div><a routerLink="/safaris">Our Journeys</a></div>
            <div><a routerLink="/family-safaris">Family Safaris</a></div>
          </div>
        </div>
      </mat-grid-tile>
      @if (_breakpoint() !== 'iPhone' && _breakpoint() !== 'iPad') {
        <mat-grid-tile
          class=""
          [colspan]="tilesleft[0].cols"
          [rowspan]="tilesleft[0].rows">
          <div class="foot-header type5-headline-3">&nbsp;</div>
        </mat-grid-tile>
      }

      <mat-grid-tile
        class="footer-conversation-spacer"
        [colspan]="tilesmiddle[0].cols"
        [rowspan]="tilesmiddle2[0].rows">
        @if (_breakpoint() === 'iPhone') {
          <!-- <div class="foot-spacer-iphone"></div> -->
        }

        <div class="foot-header type5-headline-3 footer-conversation">
          <div class="conversation-header-image">
            <img src="/assets/images/G-PlanMacBook.png" />
          </div>
          <div class="lets-keep-the-conv-going">
            Let's keep the conversation going
          </div>
          <div class="plan call-type">
            Plan your private Botswana adventure with us.
          </div>
          <a routerLink="/inquire"
            ><button
              mat-fab
              extended
              class="footer-book-now custom-flat-fab rounded-button">
              BOOK NOW
            </button></a
          >
        </div></mat-grid-tile
      >
      @if (_breakpoint() !== 'iPhone' && _breakpoint() !== 'iPad') {
        <mat-grid-tile
          class=""
          [colspan]="tilesright[0].cols"
          [rowspan]="tilesright[0].rows"
          ><div class="foot-header type5-headline-3">&nbsp;</div></mat-grid-tile
        >
      }
    </mat-grid-list>
    <div class="terms wide-view-1140">
      <div class="call-type rights-reserved">
        IntoBotswana.com © Guided Safaris® 2024 • All rights reserved.
      </div>
      <div class="call-type linkterms">
        <a routerLink="/terms">Terms & User Agreement</a>
      </div>
    </div>
  </div>
</div>
