import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  Output,
  EventEmitter,
  signal,
  inject,
  ViewChild,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { CommonModule } from '@angular/common';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { IconFooterComponent } from './layout/icon-footer/icon-footer.component';
import { FooterComponent } from './layout/footer/footer.component';
import {
  RouterLinkWithHref,
  RouterOutlet,
  RouterModule,
} from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { BreakpointService } from '@shared/breakpoint.service';
import { TopSubheaderComponent } from './layout/top-subheader/top-subheader.component';
import { ViewportScroller } from '@angular/common';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Footer2025Component } from './layout/footer2025/footer2025.component';
import { GoogleAnalyticsService } from './google-analytics.service';
// import { Geo } from './geo.service';
// import { HttpClientModule } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,

  imports: [
    CommonModule,
    RouterOutlet,
    RouterLinkWithHref,
    MatSlideToggleModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatExpansionModule,
    MatListModule,
    SideNavComponent,
    IconFooterComponent,
    TopSubheaderComponent,
    FooterComponent,
    NgOptimizedImage,
    RouterModule,
    Footer2025Component,
  ],
  animations: [
    trigger('sidenavAnimation', [
      state(
        'closed',
        style({
          transform: 'translateX(-100%)',
        })
      ),
      state(
        'open',
        style({
          transform: 'translateX(0)',
        })
      ),
      transition('closed <=> open', animate('0.6s ease-out')),
    ]),
  ],
})
export class AppComponent implements OnInit {
  accessGranted = false;
  // public geo = inject(Geo);
  @ViewChild('sidenav', { static: true }) public sidenav!: MatSidenav;
  @ViewChild('sidenavToggle') sidenavToggle!: ElementRef;
  router = inject(Router);
  googleAnalyticsService = inject(GoogleAnalyticsService);
  breakpointService = inject(BreakpointService);
  _breakpoint = this.breakpointService._breakpoint;
  viewportScroller = inject(ViewportScroller);
  openedChange = new EventEmitter<boolean>();
  constructor() {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.viewportScroller.scrollToPosition([0, 0]);
        this.sidenav.close();
        this.googleAnalyticsService.sendPageView(event.urlAfterRedirects);
      });
  }
  ngOnInit() {
    this.handleNavigation();

    // console.log('AppComponent: ngOnInit called');
    // this.geo.getAccessState().subscribe(state => {
    //   if (state) {
    //     console.log('AppComponent: Access state updated', state);
    //     this.accessGranted = state.allowed;
    //     if (!state.allowed) {
    //       this.router.navigate(['/not-available']);
    //     }
    //   }
    // });
  }
  // private checkAccess() {
  //   console.log('AppComponent: Checking country access...');
  //   this.geo.checkAccess().subscribe(
  //     response => {
  //       console.log('AppComponent: Response from checkAccess:', response);
  //       if (!response.allowed) {
  //         console.log(
  //           'AppComponent: Access denied. Redirecting to not-available page...'
  //         );
  //         this.router.navigate(['/not-available']);
  //       } else {
  //         console.log('AppComponent: Access granted.');
  //       }
  //     },
  //     error => {
  //       console.error('AppComponent: Error checking country access:', error);
  //     }
  //   );
  // }
  checkScreenSize() {
    if (this._breakpoint() === 'iPhone') {
      this.sidenav.mode = 'over';
      this.sidenav.close();
    } else {
      this.sidenav.mode = 'side';
      this.sidenav.open();
    }
  }
  toggleSidenav() {
    this.openedChange.emit(!this.sidenav.opened);
  }
  private handleNavigation() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this._breakpoint() === 'iPhone') {
          this.closeSidenav();
        }
      });
  }
  private focusMainContent() {
    const mainContent = document.querySelector('main');
    if (mainContent instanceof HTMLElement) {
      mainContent.setAttribute('tabindex', '-1');
      mainContent.focus();
    }
  }

  closeSidenav() {
    if (this.sidenav) {
      // console.log('Sidenav closing');
      this.sidenav.close();
      this.blurActiveElement();
    }
  }
  private blurActiveElement() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
}
