import {
  Component,
  Pipe,
  PipeTransform,
  ViewChild,
  effect,
  inject,
  NgModule,
  signal,
  Signal,
  OnInit,
  WritableSignal,
  OnDestroy,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

import {
  Firestore,
  addDoc,
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from '@angular/fire/firestore';
import { BreakpointService } from '@app/shared/breakpoint.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Tile } from '@app/shared/tile';
import { TileSort } from '@app/shared/tilesort';
import { Grid } from '@app/shared/grid';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { Safari } from '@app/safari/safari';
import { MatChipsModule } from '@angular/material/chips';
import { LayoutModule } from '@angular/cdk/layout';
import { ContentfulService } from '@app/contentful.service';
import { NgxContentfulRichTextModule } from 'ngx-contentful-rich-text';
import { ToHtmlPipe } from '@app/to-html.pipe';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Entry } from 'contentful';

interface BottomGrid {
  fields: {
    title: string;
    subtitleSecondLine: string;
    section: string;
    photo: {
      fields: {
        file: {
          url: string;
        };
        title: string;
      };
    };
    url: string;
    slug: string;
    makeADifferenceGrid: Document;
  };
}
@Pipe({
  name: 'keys',
})
export class KeysPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return Object.keys(value);
  }
}

@NgModule({
  declarations: [KeysPipe],
  exports: [KeysPipe],
  imports: [CommonModule],
})
export class KeysPipeModule {}
@Component({
  selector: 'app-safaris-index',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    OverlayModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    JsonPipe,
    KeysPipeModule,
    MatChipsModule,
    RouterModule,
    NgxContentfulRichTextModule,
    MatChipsModule,
  ],

  templateUrl: './safaris-index.component.html',
  styleUrls: ['./safaris-index.component.scss'],
})
export class SafarisIndexComponent implements OnInit, OnDestroy {
  add = false;
  is404: boolean = false;
  private routeSub!: Subscription;

  private ContentfulService = inject(ContentfulService);

  private _formBuilder = inject(FormBuilder);
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private title = inject(Title);
  threegrid: BottomGrid[] = [];

  breakpointService = inject(BreakpointService);
  _breakpoint: Signal<string> = this.breakpointService._breakpoint;

  // private firestore: Firestore = inject(Firestore); // inject Cloud Firestore

  gridlist: Grid[] = [{ cols: 4, rowHeight: '30px', gutterSize: '10px' }];
  filterlist: Grid[] = [{ cols: 4, rowHeight: '30px', gutterSize: '10px' }];

  tiles: Tile[] = [];

  safariList: Grid[] = [{ cols: 3, rowHeight: '1:1', gutterSize: '40px' }];
  safariTile: Tile[] = [{ cols: 1, rows: 1 }];
  safariFeatTile: Tile[] = [{ cols: 3, rows: 1 }];

  gridInterest: Grid[] = [];

  tilesInterest: TileSort[] = [
    {
      description: 'Big Cat photography',
      cols: 1,
      rows: 1,
      color: 'pink',
      interests: 'photography',
      checked: false,
    },
    {
      description: 'Gorilla Safaris',
      cols: 1,
      rows: 1,
      color: 'orange',
      interests: 'gorilla',
      checked: false,
    },
    {
      description: 'Birding hotspots',
      cols: 1,
      rows: 1,
      color: 'lightblue',
      interests: 'birdinghotspots',
      checked: false,
    },
    {
      description: 'Off the grid',
      cols: 1,
      rows: 1,
      color: 'lightgreen',
      interests: 'offthegrid',
      checked: false,
    },
    {
      description: 'Desert experiences',
      cols: 1,
      rows: 1,
      color: 'yellow',
      interests: 'desertexperiences',
      checked: false,
    },
    {
      description: 'Private Safaris',
      cols: 1,
      rows: 1,
      color: 'grey',
      interests: 'privatesafaris',
      checked: false,
    },
    {
      description: 'Family Safaris',
      cols: 1,
      rows: 1,
      color: 'purple',
      interests: 'familysafaris',
      checked: false,
    },
  ];

  interestPanelOpenState = true;
  regionpanelOpenState = false;

  interests = this._formBuilder.group({
    photography: false,
    gorilla: false,
    birdinghotspots: false,
    offthegrid: false,
    desertexperiences: false,
    privatesafaris: false,
    familysafaris: false,
  });
  interests$!: Observable<Partial<{ [key: string]: boolean | null }>>;
  interestsSubscription$!: Subscription;

  interestChips: { description: string }[] = [];
  removable = true;
  safarisinitSnapsnot: Entry<any>[] = [];

  safarisSignal!: WritableSignal<Entry<any>[]>;
  filtertedSafarisSignal!: Signal<Safari[]>;
  selectedInterests: string[] = [];
  filteredSafaris: any[] = [];

  constructor() {
    effect(() => {
      if (this._breakpoint() === 'iPhone') {
        this.gridlist = [{ cols: 6, rowHeight: '70px', gutterSize: '0px' }];
        this.filterlist = [{ cols: 2, rowHeight: '25px', gutterSize: '10px' }];
        this.tiles = [{ cols: 3, rows: 1 }];
      }
      if (this._breakpoint() === 'iPad') {
        this.gridlist = [{ cols: 6, rowHeight: '70px', gutterSize: '0px' }];
        this.filterlist = [{ cols: 3, rowHeight: '30px', gutterSize: '10px' }];
        this.tiles = [{ cols: 3, rows: 1 }];
        this.safariList = [{ cols: 4, rowHeight: '1:1', gutterSize: '40px' }];
      }
      if (this._breakpoint() === 'iPadPro') {
        this.gridlist = [{ cols: 6, rowHeight: '65px', gutterSize: '0px' }];
        this.filterlist = [{ cols: 4, rowHeight: '30px', gutterSize: '10px' }];
        this.tiles = [{ cols: 3, rows: 1 }];
        this.safariList = [{ cols: 2, rowHeight: '1:1', gutterSize: '40px' }];
      }
      if (this._breakpoint() === 'Desktop') {
        this.gridlist = [{ cols: 4, rowHeight: '30px', gutterSize: '10px' }];
        this.filterlist = [{ cols: 4, rowHeight: '30px', gutterSize: '10px' }];
        this.tiles = [{ cols: 1, rows: 1 }];
        this.safariList = [{ cols: 3, rowHeight: '1:1', gutterSize: '40px' }];
        this.safariTile = [{ cols: 1, rows: 1 }];
        this.safariFeatTile = [{ cols: 3, rows: 1 }];
      }
      if (this._breakpoint() === 'Large') {
        this.gridlist = [{ cols: 6, rowHeight: '70px', gutterSize: '0px' }];
        this.filterlist = [{ cols: 5, rowHeight: '30px', gutterSize: '10px' }];
        this.tiles = [{ cols: 3, rows: 1 }];
      }
    });
  }

  ngOnInit() {
    this.getAllSafaris();
    this.routeSub = this.route.data.subscribe(data => {
      this.is404 = data['is404'] || false;

      if (this.is404) {
        // Handle 404 case
        this.is404 = true;
        this.router.navigate(['/page-not-found'], { skipLocationChange: true });
      } else {
        // Regular case
        this.is404 = false;
      }
    });
  }
  getAllSafaris() {
    this.ContentfulService.getSafaris().then(safaris => {
      const sortedSafaris = safaris.sort((a, b) => {
        const priorityA = a.fields['prioritySidenav'] as number | undefined;
        const priorityB = b.fields['prioritySidenav'] as number | undefined;

        // If prioritySidenav is not set, use a large number to push it to the end
        const numPriorityA =
          typeof priorityA === 'number' ? priorityA : Number.MAX_SAFE_INTEGER;
        const numPriorityB =
          typeof priorityB === 'number' ? priorityB : Number.MAX_SAFE_INTEGER;

        return numPriorityA - numPriorityB;
      });

      this.safarisinitSnapsnot = sortedSafaris;
      this.filteredSafaris = sortedSafaris;

      // Set the threegrid data from the first safari (index 0)
      if (sortedSafaris.length > 0 && sortedSafaris[0].fields['threeGrid']) {
        this.threegrid = sortedSafaris[0].fields[
          'threeGrid'
        ] as unknown as BottomGrid[];
      }
    });
  }
  onInterestChange(description: string, isChecked: boolean) {
    if (isChecked) {
      // Add a chip when the checkbox is checked
      this.interestChips.push({ description: description });
      this.selectedInterests.push(description);
    } else {
      // Remove the chip when the checkbox is unchecked
      const index = this.interestChips.findIndex(
        chip => chip.description === description
      );
      if (index !== -1) {
        this.interestChips.splice(index, 1);
      }
      const interestIndex = this.selectedInterests.indexOf(description);
      if (interestIndex !== -1) {
        this.selectedInterests.splice(interestIndex, 1);
      }
    }
    // Call filterSafaris method after updating selectedInterests
    this.filterSafaris();
  }

  filterSafaris() {
    if (this.selectedInterests.length > 0) {
      this.filteredSafaris = this.safarisinitSnapsnot.filter(safari => {
        // Check if safari.fields['browsebyinterest'] exists and is an array
        const browseByInterest = safari.fields['browsebyinterest'];
        return (
          Array.isArray(browseByInterest) &&
          this.selectedInterests.some(interest =>
            browseByInterest.includes(interest)
          )
        );
      });
    } else {
      // If no interests are selected, show all safaris
      this.selectedInterests = [];
      this.filteredSafaris = this.safarisinitSnapsnot;
    }
  }
  resetSafaris() {
    this.selectedInterests = [];
    this.filteredSafaris = this.safarisinitSnapsnot;
    this.tilesInterest.forEach(tile => (tile.checked = false)); // Add this line
    this.interestChips = [];
  }
  remove(chip: any) {
    // Uncheck the corresponding checkbox
    const tile = this.tilesInterest.find(
      tile => tile.description === chip.description
    );
    if (tile) {
      tile.checked = false;
    }

    // Remove the chip
    const index = this.interestChips.indexOf(chip);
    if (index >= 0) {
      this.interestChips.splice(index, 1);
    }

    // Remove the interest from selectedInterests
    const interestIndex = this.selectedInterests.indexOf(chip.description);
    if (interestIndex !== -1) {
      this.selectedInterests.splice(interestIndex, 1);
    }

    // Update the filtered safaris list
    this.filterSafaris();
  }
  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
