import { Component, effect, inject } from '@angular/core';

import { Grid } from '@shared/grid';
import { Tile } from '@shared/tile';
import { BreakpointService } from '@shared/breakpoint.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatGridListModule,
    RouterModule,
    CommonModule,
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  breakpointService = inject(BreakpointService);
  _breakpoint = this.breakpointService._breakpoint;

  gridlist: Grid[] = [{ cols: 6, rowHeight: '70px', gutterSize: '0px' }];
  tilesleft: Tile[] = [{ cols: 3, rows: 1 }];
  tilesmiddle: Tile[] = [{ cols: 6, rows: 1 }];
  tilesmiddle2: Tile[] = [{ cols: 6, rows: 1 }];
  tilesright: Tile[] = [{ cols: 3, rows: 1 }];

  constructor() {
    effect(() => {
      if (this._breakpoint() === 'iPhone') {
        this.gridlist = [{ cols: 1, rowHeight: '100px', gutterSize: '30px' }];
        this.tilesleft = [{ cols: 1, rows: 2 }];
        this.tilesright = [{ cols: 1, rows: 2 }];
        this.tilesmiddle = [{ cols: 1, rows: 1 }];
        this.tilesmiddle2 = [{ cols: 1, rows: 3 }];
      }
      if (this._breakpoint() === 'iPad') {
        this.gridlist = [{ cols: 12, rowHeight: '100px', gutterSize: '30px' }];
        this.tilesleft = [{ cols: 4, rows: 2 }];
        this.tilesright = [{ cols: 4, rows: 2 }];
        this.tilesmiddle = [{ cols: 12, rows: 1 }];
        this.tilesmiddle2 = [{ cols: 8, rows: 3 }];
      }
      if (this._breakpoint() === 'iPadPro') {
        this.gridlist = [{ cols: 12, rowHeight: '200px', gutterSize: '30px' }];
        this.tilesleft = [{ cols: 3, rows: 1 }];
        this.tilesmiddle = [{ cols: 6, rows: 1 }];
        this.tilesmiddle2 = [{ cols: 6, rows: 2 }];
        this.tilesright = [{ cols: 3, rows: 2 }];
      }
      if (this._breakpoint() === 'Desktop') {
        this.gridlist = [{ cols: 12, rowHeight: '225px', gutterSize: '30px' }];
        this.tilesleft = [{ cols: 3, rows: 1 }];
        this.tilesmiddle = [{ cols: 6, rows: 1 }];
        this.tilesmiddle2 = [{ cols: 6, rows: 2 }];
        this.tilesright = [{ cols: 3, rows: 1 }];
      }
      if (this._breakpoint() === 'Large') {
        this.gridlist = [{ cols: 12, rowHeight: '225px', gutterSize: '30px' }];
        this.tilesleft = [{ cols: 3, rows: 1 }];
        this.tilesmiddle = [{ cols: 6, rows: 1 }];
        this.tilesmiddle2 = [{ cols: 6, rows: 2 }];
        this.tilesright = [{ cols: 3, rows: 1 }];
      }
    });
  }
}
