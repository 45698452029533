import { Injectable, signal } from '@angular/core';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  _breakpoint = signal<string>('');
  currentScreenSize?: string;

  screenMap = new Map([
    [Breakpoints.XSmall, 'iPhone'], // max-width: 599.98px
    [Breakpoints.Small, 'iPad'], // min-width: 600px and max-width: 959.98px
    [Breakpoints.Medium, 'iPadPro'], // min-width: 960px and max-width: 1279.98px
    [Breakpoints.Large, 'Desktop'], // min-width: 1280px and max-width: 1919.98px
    [Breakpoints.XLarge, 'Large'], // min-width: 1920px
  ]);
  destroyed = new Subject<void>();

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall, // max-width: 599.98px
        Breakpoints.Small, // min-width: 600px and max-width: 959.98px
        Breakpoints.Medium, // min-width: 960px and max-width: 1279.98px
        Breakpoints.Large, // min-width: 1280px and max-width: 1919.98px
        Breakpoints.XLarge, // min-width: 1920px
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this._breakpoint.set(this.screenMap.get(query) || '');
            this.currentScreenSize = this.screenMap.get(query) || '';
            // console.log('Breakpoint: ' + this.currentScreenSize);
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
