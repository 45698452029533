<!--
Side Nav
-->

<div
  id="sidenav {{ _breakpoint() }}"
  [ngClass]="{
    Large: _breakpoint() === 'Large',
    Desktop: _breakpoint() === 'Desktop',
    iPadPro: _breakpoint() === 'iPadPro',
    iPad: _breakpoint() === 'iPad',
    iPhone: _breakpoint() === 'iPhone',
  }">
  <div class="sidenav-icons">
    <div class="background-image"></div>

    <button
      (click)="sidenavClose.emit()"
      aria-label="Close side navigation"
      class="sidenav-close-close">
      <div class="sidenav-close-phone"></div>
    </button>
    <div class="logo-container">
      <img src="/assets/COPYRIGHT-GSIB.png" alt="Logo" class="logo" />
    </div>
  </div>
  <div class="ib-sidenav-sections">
    <div class="side-nav-container ib-sidenav-section-1">
      <div class="sidenav-accordion">
        <mat-accordion displayMode="flat">
          <mat-expansion-panel
            class="mat-elevation-z0"
            hideToggle
            (opened)="safarisPanelOpenState = true"
            (closed)="safarisPanelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-title"
                >{{ 'Private Safaris' | uppercase }}
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon class="custom-expansion-indicator"></mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-list role="navigation" class="side-navigation-links">
              @if (safarisinitSnapsnot) {
                @for (safari of safarisinitSnapsnot; track $index) {
                  <mat-list-item>
                    <a
                      [routerLink]="['/safari', safari.fields['slug']]"
                      routerLinkActive="safari-active">
                      {{ safari.fields['safari'] }}
                    </a>
                  </mat-list-item>
                }
              }
            </mat-list>
          </mat-expansion-panel>
          <mat-expansion-panel
            class="mat-elevation-z0"
            hideToggle
            (opened)="campsPanelOpenState = true"
            (closed)="campsPanelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-title">
                {{ 'Luxury Camps' | uppercase }}
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon class="custom-expansion-indicator"></mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-list role="navigation" class="side-navigation-links">
              @if (campsinitSnapsnot) {
                @for (camp of campsinitSnapsnot; track $index) {
                  <mat-list-item>
                    <a
                      [routerLink]="['/camps', camp.fields['slug']]"
                      routerLinkActive="safari-active"
                      (click)="onSidenavClose()">
                      {{ camp.fields['campName'] }}
                    </a>
                  </mat-list-item>
                }
              }
            </mat-list>
          </mat-expansion-panel>

          <mat-expansion-panel
            class="mat-elevation-z0"
            hideToggle
            (opened)="discoverPanelOpenState = true"
            (closed)="discoverPanelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title class="sidenav-title">
                {{ 'Experiences' | uppercase }}
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon class="custom-expansion-indicator"></mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-list role="navigation" class="side-navigation-links">
              @if (experiencesSnapsnot) {
                @for (safari of experiencesSnapsnot; track $index) {
                  <mat-list-item>
                    <a
                      [routerLink]="['/safari', safari.fields['slug']]"
                      routerLinkActive="safari-active">
                      {{ safari.fields['safari'] }}
                    </a>
                  </mat-list-item>
                }
              }
              <mat-list-item>
                <a
                  [routerLink]="['/family-safaris']"
                  routerLinkActive="safari-active"
                  >Family Safaris</a
                ></mat-list-item
              >
              <mat-list-item>
                <a
                  [routerLink]="['/savoir-faire']"
                  routerLinkActive="safari-active"
                  >What's New?</a
                ></mat-list-item
              >
              <mat-list-item>
                <a
                  [routerLink]="['/botswana-map']"
                  routerLinkActive="safari-active"
                  >Discover Botswana</a
                ></mat-list-item
              >
              <mat-list-item>
                <a
                  [routerLink]="['/how-we-host-you']"
                  routerLinkActive="safari-active"
                  >How we host you</a
                ></mat-list-item
              >
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="side-nav-container ib-sidenav-section-2">
      <div class="side-about-into-botswana-expansion-header">
        <div class="side-about-expansion-title">
          <a [routerLink]="['/guided-safaris']">
            {{ 'About us' | uppercase }} <span class="double-arrow">»</span></a
          >
          <!-- <mat-icon class="mat-side-nav-icon side-about-expansion-icon"
          >double_arrow</mat-icon
        > -->
        </div>
      </div>
      <div class="side-about-into-botswana-call-block">
        <div class="sidenav-plan">Plan your journey with us</div>
        <div class="side-nav-phone">(415) 814-6676 ext 5</div>
        <div class="sidenav-plan">Mo - Fr • 8am - 4pm PST</div>
        <div class="sidenav-plan">San Francisco, California</div>
        <div class="side-nav-send-email">
          <a [routerLink]="['/inquire']">
            <button class="send-button">Send an email</button></a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<!--</mat-sidenav>-->
