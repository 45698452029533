import { Component, Input, inject, effect, OnInit } from '@angular/core';

import { BreakpointService } from '@app/shared/breakpoint.service';
import { Observable } from 'rxjs';
import { Camp } from '@app/camps/camp';
import { Grid } from '@shared/grid';
import { Tile } from '@shared/tile';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-top-subheader',
  standalone: true,
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatTooltipModule,
    RouterModule,
    CommonModule,
  ],
  templateUrl: './top-subheader.component.html',
  styleUrls: ['./top-subheader.component.scss'],
})
export class TopSubheaderComponent implements OnInit {
  @Input() camp$!: Observable<Camp>;

  breakpointService = inject(BreakpointService);
  _breakpoint = this.breakpointService._breakpoint;

  gridlist: Grid[] = [{ cols: 12, rowHeight: '45px', gutterSize: '0' }];
  tilesleft: Tile[] = [];
  tilesright: Tile[] = [];

  ngOnInit() {
    setTimeout(() => {
      const buttons = document.querySelectorAll('.top-subheader button');
      buttons.forEach(button => {
        const span = button.querySelector('span');
        if (span) {
          span.setAttribute('data-text', span.textContent || '');
        }
      });
    });
  }
  constructor() {
    effect(() => {
      if (this._breakpoint() === 'iPhone') {
        this.gridlist = [{ cols: 12, rowHeight: '40px', gutterSize: '0' }];
        this.tilesleft = [{ cols: 3, rows: 1 }];
      }
      if (this._breakpoint() === 'iPad') {
        this.gridlist = [{ cols: 12, rowHeight: '40px', gutterSize: '0' }];
        this.tilesleft = [{ cols: 3, rows: 1 }];
      }
      if (this._breakpoint() === 'iPadPro') {
        this.gridlist = [{ cols: 12, rowHeight: '40px', gutterSize: '0' }];
        this.tilesleft = [{ cols: 3, rows: 1 }];
      }
      if (this._breakpoint() === 'Desktop') {
        this.gridlist = [{ cols: 12, rowHeight: '45px', gutterSize: '0' }];
        this.tilesleft = [{ cols: 3, rows: 1 }];
      }
      if (this._breakpoint() === 'Large') {
        this.gridlist = [{ cols: 12, rowHeight: '45px', gutterSize: '0' }];
        this.tilesleft = [{ cols: 3, rows: 1 }];
      }
    });
  }
}
