import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import * as CFRichTextTypes from '@contentful/rich-text-types';
import { NgxContentfulRichTextModule } from 'ngx-contentful-rich-text';
import { Document, BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';

interface ImageObject {
  imageUrl: string;
  description: string;
  title: string;
}

const CONFIG = {
  space: 'wkohr8d640j9',
  accessToken: 'MTopJJWhIveJV-w9SasmJoqy2KuRtH_0DSZ0fmxJxhs',

  contentTypeIds: {
    product: 'productCamps',
    articles: 'savoirFaire',
    safari: 'safaris',
    pages: 'pages',
    index: 'index',
  },
};
@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
  });
  options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const { title, file } = node.data.target.fields;
        const imageUrl = file.url;
        return `<img src="${imageUrl}" alt="${title}" />`;
      },
    },
  };
  constructor() {}

  getProducts(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: CONFIG.contentTypeIds.product,
          },
          query
        )
      )
      .then(res => res.items);
  }
  getPages(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: CONFIG.contentTypeIds.pages,
          },
          query
        )
      )
      .then(res => res.items);
  }
  getIndex(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: CONFIG.contentTypeIds.index,
          },
          query
        )
      )
      .then(res => res.items);
  }

  getArticles(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: CONFIG.contentTypeIds.articles,
          },
          query
        )
      )
      .then(res => res.items);
  }
  getSafaris(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient
      .getEntries(
        Object.assign(
          {
            content_type: CONFIG.contentTypeIds.safari,
          },
          query
        )
      )
      .then(res => res.items);
  }

  // fetch products with a given slug
  // and return one of them
  getProduct(slug: string): Promise<Entry<any>> {
    return this.getProducts({ 'fields.slug': slug }).then(items => items[0]);
  }
  getPage(slug: string): Promise<Entry<any>> {
    return this.getPages({ 'fields.slug': slug }).then(items => items[0]);
  }
  getIndexPage(slug: string): Promise<Entry<any>> {
    return this.getIndex({ 'fields.slug': slug }).then(items => items[0]);
  }
  getSafari(slug: string): Promise<Entry<any>> {
    return this.getSafaris({ 'fields.slug': slug }).then(items => items[0]);
  }
  getSavoirFaireArticle(slug: string): Promise<Entry<any>> {
    return this.getArticles({ 'fields.slug': slug }).then(items => items[0]);
  }

  getProductById(id: string): Promise<Entry<any> | undefined> {
    return this.cdaClient
      .getEntries({
        content_type: CONFIG.contentTypeIds.product,
        'fields.campName': id,
      })
      .then(res => res.items[0]); // Assuming there is only one matching entry
  }
  getEntry(id: string): Promise<Entry<any> | undefined> {
    return this.cdaClient.getEntry(id).then(res => res);
  }

  extractImage(imageProperty: any): ImageObject {
    if (
      typeof imageProperty === 'object' &&
      imageProperty !== null &&
      'fields' in imageProperty
    ) {
      const fields = imageProperty.fields;

      if (fields && typeof fields === 'object') {
        const imageUrl =
          fields['file'] &&
          typeof fields['file'] === 'object' &&
          'url' in fields['file']
            ? `https:${fields['file']['url']}`
            : '';
        const description =
          typeof fields['description'] === 'string'
            ? fields['description']
            : '';
        const title =
          typeof fields['title'] === 'string' ? fields['title'] : '';

        return { imageUrl, description, title };
      }
    }

    // Return a default ImageObject if the extraction fails
    return { imageUrl: '', description: '', title: '' };
  }
  extractFields(camp: any, fieldName: string) {
    const fields = (camp.fields[fieldName] as { fields: any }).fields;
    return {
      fields: {
        file: fields.file,
        description: fields.description,
        title: fields.title,
      },
    };
  }
}
