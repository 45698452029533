import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  inject,
  effect,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentfulService } from '@app/contentful.service';
import { NgxContentfulRichTextModule } from 'ngx-contentful-rich-text';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { BreakpointService } from '@shared/breakpoint.service';
import { RouterModule } from '@angular/router'; // Step 2: Import RouterModule

import { ActivatedRoute, Router } from '@angular/router';
import { Entry } from 'contentful';

interface Safari {
  prioritySidenav?: number; // Use '?' to indicate that the property is optional
  // Include other properties of a Safari object here
}
@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatButtonModule,
    NgxContentfulRichTextModule,
    RouterModule,
    CommonModule,
  ],
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  providers: [],
})
export class SideNavComponent implements OnInit, OnDestroy {
  // @Output() sidenavClose = new EventEmitter<void>();

  breakpointService = inject(BreakpointService);
  _breakpoint = this.breakpointService._breakpoint;
  private ContentfulService = inject(ContentfulService);
  safarisPanelOpenState = false;
  campsPanelOpenState = false;
  discoverPanelOpenState = false;
  hostYouPanelOpenState = false;
  safarisinitSnapsnot: Entry<any>[] = [];
  experiencesSnapsnot: Entry<any>[] = [];
  campsinitSnapsnot: Entry<any>[] = [];
  @Output() sidenavClose = new EventEmitter<boolean>();
  isMobile: boolean = false;
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  constructor() {
    effect(() => {
      if (this._breakpoint() === 'iPhone') {
        this.isMobile = true;
      }
      if (this._breakpoint() === 'iPad') {
        this.isMobile = false;
      }
      if (this._breakpoint() === 'iPadPro') {
        this.isMobile = false;
      }
      if (this._breakpoint() === 'Desktop') {
        this.isMobile = false;
      }
      if (this._breakpoint() === 'Large') {
        this.isMobile = false;
      }
    });
  }

  ngOnInit() {
    // this.checkScreenSize();
    this.route.params.subscribe(params => {
      const safariSlug = params['slug'];
      // Fetch your safari details based on the slug
    });
    this.getAllSafaris();
    this.getAllCamps();
  }

  getAllSafaris() {
    this.ContentfulService.getSafaris().then(safaris => {
      // Sort safaris by 'prioritySidenav', treating missing values as having a default priority
      const sortedSafaris = safaris.sort((a, b) => {
        const priorityA = a.fields['prioritySidenav'];
        const priorityB = b.fields['prioritySidenav'];

        // Assign a high default priority for null or undefined to place these at the end
        const numPriorityA =
          typeof priorityA === 'number' ? priorityA : Number.MAX_SAFE_INTEGER;
        const numPriorityB =
          typeof priorityB === 'number' ? priorityB : Number.MAX_SAFE_INTEGER;

        return numPriorityA - numPriorityB;
      });

      // Separate safaris based on the 'experience' field
      this.safarisinitSnapsnot = sortedSafaris.filter(
        safari => !safari.fields['experience']
      );
      this.experiencesSnapsnot = sortedSafaris.filter(
        safari => safari.fields['experience']
      );

      // console.log('Safaris:', this.safarisinitSnapsnot);
      // console.log('Experiences:', this.experiencesSnapsnot);
    });
  }
  getAllCamps() {
    this.ContentfulService.getProducts().then(camps => {
      // Filter camps to only include those with showInSidenav set to true
      const filteredCamps = camps.filter(
        camp => camp.fields['showInSidenav'] !== false
      );

      // Sort the filtered camps by 'priority'
      const sortedCamps = filteredCamps.sort((a, b) => {
        const priorityA = a.fields['priority'];
        const priorityB = b.fields['priority'];

        // Assign a high default priority for null or undefined to place these at the end
        const numPriorityA =
          typeof priorityA === 'number' ? priorityA : Number.MAX_SAFE_INTEGER;
        const numPriorityB =
          typeof priorityB === 'number' ? priorityB : Number.MAX_SAFE_INTEGER;

        return numPriorityA - numPriorityB;
      });

      // console.log('Sorted camps', sortedCamps);
      this.campsinitSnapsnot = sortedCamps;
    });
  }
  onSidenavClose() {
    this.sidenavClose.emit();
  }
  // getAllSafaris() {
  //   this.ContentfulService.getSafaris().then((entries: Entry<any>[]) => {
  //     // Map Entry<any>[] to Safari[]
  //     const safaris: Safari[] = entries.map(entry => ({
  //       ...entry.fields,
  //     }));

  //     // Use the Safari interface for sorting
  //     const sortedSafaris = safaris.sort((a, b) => {
  //       // Provide a default value for prioritySidenav if it's undefined
  //       const aPriority = a.prioritySidenav ?? Number.MAX_SAFE_INTEGER;
  //       const bPriority = b.prioritySidenav ?? Number.MAX_SAFE_INTEGER;

  //       return aPriority - bPriority;
  //     });

  //     // Use type assertion to assign sortedSafaris to safarisinitSnapsnot
  //     this.safarisinitSnapsnot = sortedSafaris as unknown as Entry<any>[];
  //     console.log('Sorted SIDENAV safaris', this.safarisinitSnapsnot);
  //   });
  // }
  ngOnDestroy() {}
}
