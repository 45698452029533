export const environment = {
  firebase: {
    projectId: 'intobotswana',
    appId: '1:845150328364:web:29ded1b29a86c641cfe389',
    storageBucket: 'intobotswana.appspot.com',
    apiKey: 'AIzaSyCv3PTIV32fzTqB-DpawZnLbTQjYvezieo',
    authDomain: 'intobotswana.firebaseapp.com',
    messagingSenderId: '845150328364',
    measurementId: 'G-07P8YR1CLF',
  },
  production: true
};
