import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  sendPageView(url: string) {
    gtag('config', 'G-0KKDY1WBJ', {
      page_path: url,
    });
  }

  sendEvent(eventName: string, params: { [key: string]: any } = {}) {
    gtag('event', eventName, params);
  }
}
