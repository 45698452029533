<div class="top-subheader-n">
  <div class="top-header-grid topnavigation" [class]="_breakpoint()">
    <div class="nav-item">
      <a routerLink="/safaris"> {{ 'Private Journeys' | uppercase }}</a>
    </div>
    <div class="nav-item">
      <a routerLink="/family-safaris">{{ 'Family Safaris' | uppercase }}</a>
    </div>
    <div class="nav-item nav-sfaire">
      <a routerLink="/savoir-faire">{{ "What's new" | uppercase }}</a>
    </div>
    <div class="nav-item nav-how-we-host">
      <a routerLink="/how-we-host-you">{{ 'How we host you' | uppercase }}</a>
    </div>
  </div>
</div>

<!-- <div class="top-subheader" [class]="_breakpoint()">
  <mat-grid-list
    class="top-header-grid"
    [cols]="gridlist[0].cols"
    [rowHeight]="gridlist[0].rowHeight"
    [gutterSize]="gridlist[0].gutterSize">
    <mat-grid-tile class="top-header-grid-tile" colspan="3" rowspan="1">
      <button
        class="type3-subtitle-2 private-journeys-btn"
        mat-button
        routerLink="/safaris">
        Private Journeys
      </button>
    </mat-grid-tile>
    <mat-grid-tile class="top-header-grid-tile" colspan="3" rowspan="1">
      <button
        class="type3-subtitle-2 family-safaris-btn"
        mat-button
        routerLink="/family-safaris">
        Family Safaris
      </button>
    </mat-grid-tile>
    <mat-grid-tile class="top-header-grid-tile" colspan="3" rowspan="1">
      <button
        class="type3-subtitle-2 whats-new-btn"
        mat-button
        routerLink="/savoir-faire">
        What's new
      </button>
    </mat-grid-tile>
    <mat-grid-tile class="top-header-grid-tile" colspan="3" rowspan="1">
      <button
        class="type3-subtitle-2 how-we-host-btn"
        mat-button
        routerLink="/how-we-host-you">
        How we host you
      </button>
    </mat-grid-tile>
  </mat-grid-list>
</div> -->
<!-- <div class="top-subheader-n2">
  <ul id="primary">
    <li><a>Private Journeys</a></li>
    <li><a>Family Safaris</a></li>
    <li><a>What's new</a></li>
    <li><a>How we host you</a></li>
  </ul>
</div> -->
