<mat-sidenav-container class="ib-container" xmlns="http://www.w3.org/1999/html">
  <!-- Side Nav -->
  <mat-sidenav
    [@sidenavAnimation]="sidenav.opened ? 'open' : 'closed'"
    class="ib-sidenav"
    role="navigation"
    #sidenav
    mode="over"
    [class.is-mobile]="_breakpoint() === 'iPhone'"
    [ngClass]="{
      Large: _breakpoint() === 'Large',
      Desktop: _breakpoint() === 'Desktop',
      iPadPro: _breakpoint() === 'iPadPro',
      iPad: _breakpoint() === 'iPad',
      iPhone: _breakpoint() === 'iPhone',
    }">
    <app-side-nav
      (sidenavClose)="closeSidenav()"
      class="app-main-sidenav"></app-side-nav>
  </mat-sidenav>
  <!-- Side Nav -->
  <!-- @if (geo.getAccessState() | async) { -->
  <!-- @if (accessGranted) { -->
  <mat-sidenav-content role="main" class="content" tabindex="-1">
    <div
      id="ib_main {{ _breakpoint() }}"
      [ngClass]="{
        Large: _breakpoint() === 'Large',
        Desktop: _breakpoint() === 'Desktop',
        iPadPro: _breakpoint() === 'iPadPro',
        iPad: _breakpoint() === 'iPad',
        iPhone: _breakpoint() === 'iPhone',
      }">
      <!-- Toolbar -->
      <mat-toolbar color="primary" class="main-toolbar">
        <div class="wide-view-1140">
          <div class="menu-control">
            <button
              #sidenavToggle
              class="main-nav-open"
              tabindex="-1"
              mat-icon-button
              (click)="sidenav.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
            @if (_breakpoint() !== 'iPhone') {
              <div
                (click)="sidenav.toggle()"
                class="ib-main-submenu ib-menu-type mat-subtitle-2"></div>
            }
          </div>

          <span class="main-menu-title">
            <div class="logo">
              <div>
                <!-- 88 x 70 53 x 42 -->
                <a href="/"
                  ><img
                    ngSrc="/assets/images/Oli-Main.png"
                    width="44"
                    height="35"
                    alt="Into Botswana | Guided Safaris"
                    priority
                /></a>
              </div>
              @if (_breakpoint() !== 'iPhone') {
                <div class="intobotswana-brand"></div>
              }
            </div>
          </span>

          <div class="ib-menu-type ib-menu-type-inquire">
            <a routerLink="/inquire"> {{ 'Contact' | uppercase }}</a>
          </div>
        </div>
      </mat-toolbar>

      <!-- Toolbar -->
      <app-top-subheader [className]="_breakpoint()" />
      <!-- Router outlet & content -->
      <router-outlet />
      <!-- <app-icon-footer /> -->
      <app-footer [className]="_breakpoint()" />
      <!-- <app-footer2025 /> -->
    </div>
  </mat-sidenav-content>
  <!-- } -->
  <!-- } -->
</mat-sidenav-container>
