import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
  isDevMode,
  NgZone,
} from '@angular/core';
import {
  provideRouter,
  RouterModule,
  Routes,
  withComponentInputBinding,
  withDebugTracing,
  withRouterConfig,
  Router,
  Scroll,
} from '@angular/router';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './app/page-not-found/page-not-found.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideLottieOptions } from 'ngx-lottie';
// import { authGuard } from '@app/auth/auth.guard';
import { CloudflareStreamModule } from '@cloudflare/stream-angular';
import { OverlayModule } from '@angular/cdk/overlay';
import { ViewportScroller } from '@angular/common';
import { GoogleAnalyticsService } from '@app/google-analytics.service';
import { SafarisIndexComponent } from './app/safaris/safaris-index/safaris-index.component';
import { Geo } from '@app/geo.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { AppInitializerService } from '@app/app-initializer.service';
import { getAuth, provideAuth } from '@angular/fire/auth';

if (environment.production) {
  enableProdMode();
}
function initGeoCheck(http: HttpClient, ngZone: NgZone) {
  return () =>
    new Promise<void>(resolve => {
      const geo = new Geo(http);
      geo.checkAccess().subscribe(
        response => {
          ngZone.run(() => {
            if (!response.allowed) {
              document.body.innerHTML = '';
            }
            resolve();
          });
        },
        error => {
          console.error('Error checking access:', error);
          resolve(); // Resolve even on error to not block app initialization
        }
      );
    });
}

function handleScrollEvents(
  router: Router,
  viewportScroller: ViewportScroller
) {
  router.events.subscribe(event => {
    if (event instanceof Scroll) {
      if (event.position) {
        // Scroll to the stored position
        viewportScroller.scrollToPosition(event.position);
      } else if (event.anchor) {
        // Scroll to the anchor
        viewportScroller.scrollToAnchor(event.anchor);
      } else {
        // Scroll to the top
        viewportScroller.scrollToPosition([0, 0]);
      }
    }
  });
}
export const appRoutes: Routes = [
  {
    path: 'camps',
    title: 'Camps',
    loadComponent: () =>
      import('./app/camps/camps-index/camps-index.component').then(
        c => c.CampsIndexComponent
      ),
  },
  // {
  //   path: 'camps/:id',
  //   loadChildren: () =>
  //     import('./app/camps/camp/camp.component').then(m => m.CampComponent),
  // },

  {
    path: 'camps/:id',
    loadComponent: () =>
      import('./app/camps/camp/camp.component').then(c => c.CampComponent),
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'safaris',
    title:
      'Private Journeys, The finest Botswana Safaris. Into Botswana | Guided Safaris',
    loadComponent: () =>
      import('./app/safaris/safaris-index/safaris-index.component').then(
        c => c.SafarisIndexComponent
      ),
  },

  { path: 'safari', redirectTo: 'safaris', pathMatch: 'full' },

  {
    path: 'safari/:id',
    loadComponent: () =>
      import('./app/safari/safari/safari.component').then(
        c => c.SafariComponent
      ),
  },
  {
    path: 'savoir-faire',
    title: 'Savoir Faire',
    loadComponent: () =>
      import(
        './app/savoir-faire/savoir-faire-index/savoir-faire-index.component'
      ).then(c => c.SavoirFaireIndexComponent),
  },
  {
    path: 'savoir-faire/:id',
    loadComponent: () =>
      import('./app/savoir-faire/savoir-faire.component').then(
        c => c.SavoirFaireComponent
      ),
  },

  {
    path: '',
    title: 'Guided Safaris® Botswana Journeys, Into Botswana | Guided Safaris',
    loadComponent: () =>
      import('./app/home/home.component').then(c => c.HomeComponent),
    data: { pageId: 'index' },
  },
  {
    path: 'family-safaris',
    title: 'Private Family Safaris, Into Botswana | Guided Safaris',
    loadComponent: () =>
      import('./app/home/home.component').then(c => c.HomeComponent),
    data: { pageId: 'family-safaris' },
  },
  //botswana-map
  {
    path: 'botswana-map',
    title: 'Bostwana Map, Into Botswana | Guided Safaris',
    loadComponent: () =>
      import('./app/home/home.component').then(c => c.HomeComponent),
    data: { pageId: 'botswana-map' },
  },

  //How we host you
  {
    path: 'how-we-host-you',
    title: 'How we host you • Into Botswana | Guided Safaris',
    loadComponent: () =>
      import('./app/home/home.component').then(c => c.HomeComponent),
    data: { pageId: 'how-we-host-you' },
  },
  //GS
  {
    path: 'guided-safaris',
    title: 'Guided Safaris, Into Botswana | Guided Safaris',
    loadComponent: () =>
      import('./app/home/home.component').then(c => c.HomeComponent),
    data: { pageId: 'guided-safaris' },
  },
  {
    path: 'inquire',
    title: 'Plan your private Safari • Into Botswana | Guided Safaris',
    loadComponent: () =>
      import('./app/home/inquire/inquire.component').then(
        c => c.InquireComponent
      ),
  },
  {
    path: 'terms',
    loadComponent: () =>
      import('./app/terms/terms.component').then(m => m.TermsComponent),
    title: 'Terms and Conditions',
  },
  {
    path: 'auth',
    loadComponent: () =>
      import('./app/auth/auth.component').then(c => c.AuthComponent),
  },
  // TODO
  // All redirects
  // ... other routes ...
  {
    path: 'page-not-found',
    component: SafarisIndexComponent,
    data: { is404: true },
  },
  { path: '**', redirectTo: '/page-not-found' }, // Redirect all other routes to 404
];

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      HttpClientModule,
      RouterModule.forRoot(appRoutes, {
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
        scrollPositionRestoration: 'disabled',
        anchorScrolling: 'disabled',
      }),
      BrowserModule,
      CloudflareStreamModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideFirestore(() => getFirestore()),
      provideAuth(() => getAuth())
    ),
    Geo, // Add Geo service here
    // AppInitializerService, // Add AppInitializerService here
    {
      provide: APP_INITIALIZER,
      useFactory: (http: HttpClient, ngZone: NgZone) =>
        initGeoCheck(http, ngZone),
      deps: [HttpClient, NgZone],
      multi: true,
    },
    provideAnimations(),
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),

    provideRouter(
      appRoutes,
      // withDebugTracing(), // Router tracing & debugging

      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      }),
      withComponentInputBinding()
    ),
    importProvidersFrom(OverlayModule),
    {
      provide: 'APP_INITIALIZER',
      useFactory: (router: Router, viewportScroller: ViewportScroller) => () =>
        handleScrollEvents(router, viewportScroller),
      deps: [Router, ViewportScroller],
      multi: true,
    },
    GoogleAnalyticsService,
  ],
}).catch(err => console.error(err));
function loadRemoteModule(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
