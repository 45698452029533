import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, tap, catchError } from 'rxjs';
import { isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Geo {
  private functionUrl =
    'https://us-central1-intobotswana.cloudfunctions.net/checkCountry';
  private accessState = new BehaviorSubject<{
    allowed: boolean;
    country: string;
  } | null>(null);

  constructor(private http: HttpClient) {}

  checkAccess(): Observable<{ allowed: boolean; country: string }> {
    // console.log('Geo service: checkAccess called');
    // console.log('Geo service: Is dev mode?', isDevMode());

    if (isDevMode()) {
      console.log('Development mode detected');
      const devModeResponse = { allowed: true, country: 'DEV' };
      this.accessState.next(devModeResponse);
      return of(devModeResponse);
    }

    const cachedAccess = this.getCachedAccess();
    if (cachedAccess) {
      // console.log('GS: Using cached access', cachedAccess);
      this.accessState.next(cachedAccess);
      return of(cachedAccess);
    }

    // console.log('GS: Calling checkCountry function...');
    const headers = new HttpHeaders({
      'X-Is-Local-Development': 'false',
    });

    return this.http
      .get<{ allowed: boolean; country: string }>(this.functionUrl, { headers })
      .pipe(
        tap(response => {
          // console.log('Geo service: Received response:', response);
          if (
            response.allowed === undefined ||
            response.country === undefined
          ) {
            console.warn('GS: Unexpected API response structure', response);
          }
          this.accessState.next(response);
          if (response.allowed) {
            this.cacheAccess(response);
          }
        }),
        catchError(error => {
          console.error('GS: Error in checkAccess:', error);
          const errorResponse = { allowed: false, country: 'ERROR' };
          this.accessState.next(errorResponse);
          return of(errorResponse);
        })
      );
  }

  private getCachedAccess(): { allowed: boolean; country: string } | null {
    const cachedData = localStorage.getItem('geoAccessCache');
    // console.log('Cached access:', cachedData);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const now = new Date().getTime();
      if (now - timestamp < 24 * 60 * 60 * 1000) {
        // 24 hours in milliseconds
        return data;
      } else {
        localStorage.removeItem('geoAccessCache');
      }
    }
    return null;
  }

  private cacheAccess(data: { allowed: boolean; country: string }): void {
    const cacheData = {
      data,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem('geoAccessCache', JSON.stringify(cacheData));
  }
}
